<template>
	<component :is="customTheme" :microsite="microsite">
		<component :is="section" :current_store="current_store"></component>
	</component>
</template>

<script>
	import { computed } from 'vue';
	import composableStore from '@/composables/store';
	import composableMicrosite from '@/composables/microsite';

	export default {
		setup() {
			const { isDefined, current_store } = composableStore();
			const { microsite } = composableMicrosite();

			const section = computed(() => {
				if(!isDefined.value) return null;
				var component_type = null;

				switch(current_store.value.type) {
					case 'store':
						component_type = 'catalog-index';
						break;
					case 'market':
						component_type = 'store-type-market-index';
						break;
					case 'headquarters':
						component_type = 'store-type-headquarters-index';
						break;
				}

				return component_type;
			});

			const customTheme = computed(() => {
				return `layouts-store-themes-${(microsite.value?.theme) ? microsite.value.theme : 'default'}`;
			});

			return { current_store, section, customTheme, microsite };
		}
	}
</script>